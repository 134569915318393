import axios from 'axios';

import { getEndpoint } from '../../utils/endpoints';
import { shoppingBasketCountSelector } from '../../utils/selectors/shoppingBasketSelector';
import { currentCatalogSelector } from '../../utils/selectors/productCatalogSelectors';
import {
  AddProductAction,
  BasketFailureAction,
  DecreaseProductAction,
  DeleteProductAction,
  LoadArticleCounterSuccessAction,
  LoadListSuccessAction,
  SetProductAction,
} from './actions.types';
import { Dispatch } from 'redux';
import { BasketItem } from './reducer.types';
import { getEndpointType } from 'utils/hooks/use-endpoint';
import { Log } from 'services/log';

export const BASKET_FAILURE = 'basket/BASKET_FAILURE';
export const ADD_PRODUCT = 'basket/ADD_PRODUCT';
export const COMMIT_PRODUCT = 'basket/COMMIT_PRODUCT';
export const DECREASE_PRODUCT = 'basket/DECREASE_PRODUCT';
export const SET_PRODUCT = 'basket/SET_PRODUCT';
export const DELETE_PRODUCT = 'basket/DELETE_PRODUCT';
export const LOAD_LIST = 'basket/LOAD_LIST';
export const ARTICLE_COUNT_SUCCESS = 'basket/ARTICLE_COUNT_SUCCESS';
export const BASKET_LOADING = 'basket/BASKET_LOADING';
export const BASKET_FINISHLOADING = 'basket/BASKET_FINISHLOADING';

/**
 * Set error
 *
 * @param {Object} error
 */
function basketFailure(error: RequestError): BasketFailureAction {
  return {
    type: BASKET_FAILURE,
    error,
  };
}

type SaveableAction =
  | AddProductAction
  | DecreaseProductAction
  | SetProductAction
  | DeleteProductAction;
/**
 * Save data to api
 */
const saveToAPI = async (
  action: SaveableAction,
  getState: () => AppState,
  dispatch: Dispatch,
  id: string,
  product: BasketItem | undefined,
  session: string,
  ciam: Nullable<{ UID: string }>,
  actionType: SaveableAction['type'],
  tmpProduct: BasketItem,
) => {
  const state = getState();
  const lang = currentCatalogSelector(state);

  let endpoint = getEndpoint('webshop', state);
  if (ciam?.UID) {
    endpoint += `/customer/ciam/${ciam.UID}/cart`;
  } else {
    endpoint += `/cart/session/${session}`;
  }
  endpoint += `?lang=${lang}`;

  const element = state.basket.items.find(
    (entry) =>
      entry.key === action.id ||
      entry.key === action.product ||
      (action.product && entry.key === action.product.key) ||
      (action.product &&
        entry.productId === action.product.productId &&
        entry.variantId === action.product.variantId),
  );

  let updateProduct = element ?? { ...product };

  if (actionType === DELETE_PRODUCT && tmpProduct) {
    updateProduct = { ...tmpProduct, quantity: 0 };
  }

  try {
    const {
      data: {
        totalItems,
        cart: { lineItemList },
      },
    } = await axios.patch(endpoint, updateProduct);

    dispatch(loadArticleCounterSuccess(totalItems));
    dispatch(loadListSuccess(lineItemList));
    dispatch({ type: COMMIT_PRODUCT, product: updateProduct });
  } catch (e) {
    dispatch(basketFailure(e));
  }
};

/**
 * action to save changes
 * @param {Object} action
 */
function persistChange(
  action: SaveableAction,
  session: string,
  ciam: Nullable<{ uid: string }> = null,
) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const element = state.basket.items.find(
      (entry) =>
        entry.key === action.id ||
        entry.key === action.product ||
        (action.product && entry.key === action.product.key) ||
        (action.product &&
          entry.productId === action.product.productId &&
          entry.variantId === action.product.variantId),
    );

    const tempProduct = element ? { ...element } : null;
    await dispatch(action);
    saveToAPI(
      action,
      getState,
      dispatch,
      action.id,
      action.product,
      session,
      ciam,
      action.type,
      tempProduct,
    );
  };
}

/**
 * add a product to basket
 * @param {String} id
 */
export function addProduct(
  product: BasketItem,
  session: string,
  ciam: Nullable<{ uid: string }> = null,
) {
  return persistChange(
    {
      type: ADD_PRODUCT,
      product,
    },
    session,
    ciam,
  );
}

/**
 * Decreate product amount
 * @param {String} id
 */
export function decreaseProduct(
  id: string,
  session: string,
  ciam: Nullable<{ uid: string }> = null,
) {
  return persistChange(
    {
      type: DECREASE_PRODUCT,
      id,
    },
    session,
    ciam,
  );
}

/**
 * Set product to amount x
 * @param {String} id
 * @param {Int} count
 */
export function setProduct(
  id: string,
  count: number,
  session: string,
  ciam: Nullable<{ uid: string }> = null,
) {
  return persistChange(
    {
      type: SET_PRODUCT,
      id,
      count,
    },
    session,
    ciam,
  );
}

/**
 * Delete product from basket
 * @param {String} id
 */
export function deleteProduct(id: string, session: string, ciam: Nullable<{ uid: string }> = null) {
  return persistChange(
    {
      type: DELETE_PRODUCT,
      id,
    },
    session,
    ciam,
  );
}

/**
 * Save basket page article list
 *
 * @param {Array} list
 */
export function loadListSuccess(items: BasketItem[]): LoadListSuccessAction {
  return {
    type: LOAD_LIST,
    items,
  };
}

/**
 * Load basket
 */
export function loadList(session: string, lang: string, ciam: Nullable<{ uid: string }> = null) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.basket.isLoading) {
      return;
    }

    dispatch({ type: BASKET_LOADING });
    if (!lang) {
      dispatch({ type: BASKET_FINISHLOADING });

      return;
    }

    let endpoint = getEndpoint('webshop', state);
    if (ciam?.UID) {
      endpoint += `/customer/ciam/${ciam.UID}/cart`;
    } else {
      endpoint += `/cart/session/${session}`;
    }
    endpoint += `?lang=${lang}&timestamp=${new Date().getTime()}`;

    try {
      const {
        data: { lineItemList },
      } = await axios.get(endpoint);

      dispatch(loadListSuccess(lineItemList));
    } catch (error) {
      dispatch(basketFailure(error));
    } finally {
      dispatch({ type: BASKET_FINISHLOADING });
    }
  };
}

/**
 * Merge Cart
 * @param {*} session
 * @param {*} ciam
 * @param {*} lang
 */
export async function mergeCart(
  getEndpoint: getEndpointType,
  session: string,
  UID: string,
  lang: string,
) {
  const endpoint = getEndpoint('webshop', `/cart/session/${UID}?sessionId=${session}&lang=${lang}`);
  return axios.put(endpoint);
}

/**
 * Save article count
 *
 * @param {Array} list
 */
export function loadArticleCounterSuccess(count: number): LoadArticleCounterSuccessAction {
  return {
    type: ARTICLE_COUNT_SUCCESS,
    count,
  };
}

/**
 * Fetch article count
 *
 * @param {String} lang
 */
export function loadArticleCounter(
  session: string,
  lang: string,
  ciam: Nullable<{ uid: string }> = null,
) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    if (!lang) return;

    const state = getState();
    const prevCount = shoppingBasketCountSelector(state);
    let endpoint = getEndpoint('webshop', state);
    if (ciam?.UID) {
      endpoint += `/customer/ciam/${ciam.UID}/cart/count`;
    } else {
      endpoint += `/cart/${session}/count`;
    }
    endpoint += `?lang=${lang}&timestamp=${new Date().getTime()}`;

    try {
      const {
        data: { count },
      } = await axios.get(endpoint);

      if (prevCount !== count) {
        dispatch(loadArticleCounterSuccess(count));
      }
    } catch (error) {
      dispatch(basketFailure(error));
    }
  };
}

/**
 * Recaptcha check on basket page
 * @param {String} captchaKey
 */
export async function checkCartCaptcha(captchaKey: string, lang: string, state) {
  const newToken = await window.grecaptcha.execute(captchaKey);
  try {
    const { data: result } = await axios.get(
      `${getEndpoint('form', state)}/grc?grc=${newToken}&lang=${lang}`,
    );
    return result;
  } catch (e) {
    Log.error('Failed to check captcha', e);
    return false;
  }
}
